@charset "UTF-8";
/*
	by Glucône - Patrick Videira

	- MAINS COLORS
	- ALERTS & BADGES
	- BUTTONS
	- MENUS
	- MISC
	- HERO Slider
	- BREADCRUMB
	- CATEGORY
	- FOOTER
	- CTA
	- CARDS
	- COURSES
	- PLAYER
	- ICON BOX
	- COUNTER
	- QUIZZ
	- EXTENDED BOOTSTRAP
	- TINY DEFAULT

*/
/* Grey Braintop */
/* Green Braintop */
/* Red Braintop */
/* Middle Grey Braintop */
/* Light Grey Braintop */
/*---------------- css loader ----------------*/
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
/*---------------- dot-move ----------------*/
@-webkit-keyframes dot-move {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}
@-webkit-keyframes dot-move {
  from {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -ms-transform: translateX(88px);
    transform: translateX(88px);
  }
}
@keyframes dot-move {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}
/*---------------- dot-move 2 ----------------*/
@-webkit-keyframes dot-move2 {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(48px);
    transform: translateX(48px);
  }
}
@-webkit-keyframes dot-move2 {
  from {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -ms-transform: translateX(48px);
    transform: translateX(48px);
  }
}
@keyframes dot-move2 {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(48px);
    transform: translateX(48px);
  }
}
/*============== play-button-pulse ==============*/
@-webkit-keyframes play-button-pulse {
  0% {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
}
@-moz-keyframes play-button-pulse {
  0% {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
}
@-ms-keyframes play-button-pulse {
  0% {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
}
@-o-keyframes play-button-pulse {
  0% {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
}
@keyframes play-button-pulse {
  0% {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
}
@keyframes blink {
  50% {
    opacity: 1;
  }
}
/*-===============================
    MIXIN
===============================-*/
/*-===============================
    MAIN COLORS
===============================-*/
/*====== bg =======*/
.bg-themecolor-1, .bg-primary {
  background-color: #3f3f3f !important;
}

.bg-themecolor-2, .bg-secondary {
  background-color: #b2cc00 !important;
}

.bg-themecolor-3, .bg-terciary {
  background-color: #ff0000 !important;
}

.bg-themecolor-4, .bg-quaternary {
  background-color: #6d6d6d !important;
}

.bg-themecolor-5, .bg-fifth {
  background-color: #f5f5f5 !important;
}

.bg-themecolor-6, .bg-sixth {
  background-color: #0e0e1e !important;
}

.bg-color-9 {
  background-color: #f36f32 !important;
}

.bg-color-10 {
  background-color: #11a5fd !important;
}

.bg-black {
  background-color: #000 !important;
}

/*====== text-color =======*/
.text-color-1, .text-primary {
  color: #3f3f3f !important;
}

.text-color-2, .text-secondary {
  color: #b2cc00 !important;
}

.text-color-3, .text-tercary {
  color: #ff0000 !important;
}

.text-color-4 {
  color: #6d6d6d !important;
}

.text-color-5 {
  color: #f5f5f5 !important;
}

.text-color-6 {
  color: #0e0e1e !important;
}

.text-color-9 {
  color: #f36f32 !important;
}

/*====== links =======*/
a {
  color: #b2cc00;
}

a:hover {
  color: #3f3f3f;
}

a.podcast-link {
  color: #f36f32;
}

a.podcast-link:hover {
  color: #3f3f3f;
}

.alert-danger a {
  color: #3f3f3f;
  text-decoration: underline;
}

.alert-danger a:hover {
  color: #3f3f3f;
  text-decoration: none;
}

.section__meta {
  color: #3f3f3f;
}

/*-===============================
    ALERTS & BADGES
===============================-*/
/*======= alert =======*/
.alert-success a {
  color: #155724;
  text-decoration: underline;
}

.alert-success a:hover {
  color: #0b2e13;
  text-decoration: underline;
}

/*======= badge-label =======*/
.badge-label-2 {
  background-color: #ff0000;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  padding: 4px 10px 4px 12px;
  -webkit-border-radius: 0 0 4px 0;
  -moz-border-radius: 0 0 4px 0;
  border-radius: 0 0 4px 0;
  position: relative;
}

.badge-label-3 {
  background-color: #ff0000d4;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  padding: 4px 10px 4px 12px;
  -webkit-border-radius: 4px 0 0 0;
  -moz-border-radius: 4px 0 0 0;
  border-radius: 4px 0 0 0;
  position: relative;
}

.badge-label-4 {
  background-color: #3f3f3fb5;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  padding: 4px 10px 4px 12px;
  -webkit-border-radius: 0 0 0 4px;
  -moz-border-radius: 0 0 0 4px;
  border-radius: 0 0 0 4px;
  position: relative;
}

.badge-label-4.doctor {
  border-right: 13px solid #ff0000;
  transform: translateX(13px);
}

.badge-label-4.pharmacist {
  border-right: 13px solid #459c5c;
  transform: translateX(13px);
}

.badge-label-4.bg-dark {
  background-color: #3f3f3f;
}

.section-heading .badge-label-rounded-2 {
  font-size: 16px;
}

.badge-label-rounded-2.small {
  font-size: 80%;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  padding: 2px 6px;
}

.badge-label-rounded-2 {
  background-color: #ff0000;
  color: #fff;
  display: inline-block;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 4px 12px;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.2em;
}

.badge-label-rounded-3 {
  border: #3f3f3f47 1px solid;
  display: inline-block;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 4px 12px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.2em;
}

.badge-label-rounded-3.doctor {
  border-bottom: 6px solid #ff0000;
}

.badge-label-rounded-3.doctor strong {
  color: #ff0000;
}

.badge-label-rounded-3.pharmacist {
  border-bottom: 6px solid #459c5c;
}

.badge-label-rounded-3.pharmacist strong {
  color: #459c5c;
}

.badge-label-rounded-3.others {
  border-bottom: 6px solid #3f3f3f;
}

.badge-label-rounded-3.others strong {
  color: #3f3f3f;
}

/*-===============================
    BUTTONS
===============================-*/
.btn-primary {
  color: #fff !important;
  background-color: #3f3f3f;
  border-color: #3f3f3f;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  background-color: #fff !important;
  color: #3f3f3f !important;
  border-color: #3f3f3f !important;
}

.btn-outline-primary {
  color: #3f3f3f !important;
  background-color: #fff;
  border-color: #3f3f3f;
}

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active {
  color: #b2cc00 !important;
  background-color: #fff !important;
  border-color: #b2cc00 !important;
}

.btn-secondary {
  color: #fff !important;
  background-color: #b2cc00 !important;
  border-color: #b2cc00 !important;
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
  background-color: #fff !important;
  color: #b2cc00 !important;
  border-color: #b2cc00 !important;
}

.btn-ninth {
  color: #fff !important;
  background-color: #f36f32 !important;
  border-color: #f36f32 !important;
}

.btn-ninth:hover, .btn-ninth:focus, .btn-ninth:active {
  background-color: #fff !important;
  color: #f36f32 !important;
  border-color: #f36f32 !important;
}

/*===== link-collapsed =======*/
.link-collapsed {
  background-color: #fff !important;
  color: #3f3f3f !important;
  border: 1px solid #3f3f3f !important;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: rgba(81, 190, 120, 0.1);
  -webkit-border-radius: 0.2rem;
  -moz-border-radius: 0.2rem;
  border-radius: 0.2rem;
  padding: 3px 16px;
}

.link-collapsed:hover, .link-collapsed:focus, .link-collapsed:active {
  color: #fff !important;
  background-color: #3f3f3f !important;
  border: 1px solid #3f3f3f !important;
}

/*-===============================
    HOME
===============================-*/
.category-item-layout-2.bg-color-9 .icon-element {
  color: #f36f32;
}

.category-item-layout-2.bg-color-9:hover .icon-element {
  background-color: #f36f32;
  color: #fff;
}

.category--item-layout-2.bg-color-9:hover {
  background-color: white !important;
}

.category--item-layout-2.bg-color-9:hover .cat__title {
  color: #f36f32;
}

.category-item-layout-2.bg-color-10 .icon-element {
  color: #11a5fd;
}

.category-item-layout-2.bg-color-10:hover .icon-element {
  background-color: #11a5fd;
  color: #fff;
}

.category--item-layout-2.bg-color-10:hover {
  background-color: white !important;
}

.category--item-layout-2.bg-color-10:hover .cat__title {
  color: #11a5fd;
}

@media (max-width: 991px) {
  .hero-category {
    margin-top: 20px !important;
  }

  .section-heading {
    text-align: center;
  }
}
@media (max-width: 480px) {
  .single-slide-item .section-heading .section__desc {
    line-height: 2.5em;
  }
}
@media (max-width: 575px) {
  .single-slide-item .section-heading .separatorBox {
    display: block;
  }

  /*
  .category--item-layout-2 {
  	.category-content {
  		display: flex;
  		justify-content: center;
  		align-items: center;

  		.icon-element {
  			margin-right: 1em;
  		}
  		h2 {
  			display: contents;
  		}

  	}
  	.cat__title {
  		margin: 0;
  	}
  }*/
}
.single-slide-item-3 {
  padding-top: 80px;
  padding-bottom: 90px;
}

/*-===============================
    MENUS
===============================-*/
.header-menu-area {
  background-color: #fff;
}

.header-menu-content {
  background: url("../images/nav_menu_bg.png") center center repeat-y;
}

.main-menu-content {
  background-color: #3f3f3f;
}

.main-menu-content .logo-box img {
  max-height: 77px;
}

.logo-box {
  padding-top: 0;
  padding-bottom: 0;
}

.menu-category > ul > li a {
  color: #fff;
}

.menu-category > ul > li .cat-dropdown-menu > li > a {
  color: #3f3f3f;
}

.menu-category > ul > li .cat-dropdown-menu > li > a:hover {
  color: #b2cc00;
}

.main-menu {
  background-color: #3f3f3f;
}

.main-menu > ul > li a {
  color: #fff;
}

.main-menu > ul > li a.active {
  color: #b2cc00;
}

.main-menu > ul > li .dropdown-menu-item > li > a {
  color: #3f3f3f;
}

.main-menu > ul > li .dropdown-menu-item > li > a:hover {
  color: #b2cc00;
}

.main-menu > ul > li .dropdown-menu-item > li > a.active {
  color: #b2cc00;
}

@media (max-width: 991px) {
  .main-menu {
    display: none;
    margin-right: 0;
    padding: 20px 15px 15px 15px;
  }
}
.menu-wrapper {
  padding-top: 0;
  padding-bottom: 0px;
}

.side-menu-open,
.menu-toggler {
  line-height: 45px;
  background-color: rgba(128, 137, 150, 0.2);
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .menu-category {
    margin-top: -50px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .menu-category {
    margin-top: -50px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .menu-category {
    margin-top: -50px;
  }
}
@media (max-width: 991px) {
  .main-menu-links {
    padding-left: 0;
    padding-right: 0;
  }
}
.topbar-cart-btn {
  font-size: 20px;
  height: 100%;
  cursor: pointer;
  color: #3f3f3f;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.cart-dropdown-menu {
  right: -50px;
  width: 200px;
}

.cart-dropdown-menu:after {
  right: 100px;
}

.dashboard-menu-content .menu-wrapper .contact-form-action .form-control {
  background-color: white;
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .dashboard-menu-content .main-menu {
    padding-top: 20px;
  }
}
@media (max-width: 1024px) {
  .dashboard-sidebar .dashboard-nav-container {
    width: 100%;
    padding-top: 30px;
  }
}
.dashboard-sidebar .dashboard-nav-container.active {
  z-index: 11000;
}

.dashboard-sidebar .dashboard-nav-container .humburger-menu .humburger-menu-lines {
  top: 18px;
}

.sidebar-open {
  top: 17px;
}

/*-===============================
    MISC
===============================-*/
@media (max-width: 767px) {
  .sort-ordering {
    width: 100%;
  }
}
.noclic {
  z-index: 1;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  cursor: not-allowed;
}

.sidebar-preview-titles.bg-terciary .section-divider:after {
  background-color: #ff0000;
}

/*-===============================
    HERO Slider
===============================-*/
.single-slide-item:after {
  background-color: #494e27;
}

.single-slide-item-3:after {
  opacity: 0.6;
}

.single-slide-item-3 .section-heading .section__title {
  font-size: 36px;
  line-height: 36px;
}

@media (max-width: 991px) {
  .single-slide-item-3 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .single-slide-item-3 .section-heading .section__title {
    font-size: 36px;
    line-height: 36px;
  }
}
@media (max-width: 480px) {
  .single-slide-item-3 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .single-slide-item-3 .section-heading .section__title {
    font-size: 26px;
    line-height: 28px;
  }
}
@media (max-width: 767px) {
  .breadcrumb-content.breadcrumb-detail-content .section__title {
    font-size: 27px;
  }
}
.section__title br {
  display: inherit;
}

.section__desc br {
  display: inherit;
}

@media (max-width: 425px) {
  .single-slide-item-3 .section-heading .section__desc {
    font-size: 16px;
    line-height: 44px !important;
  }
}
/*-=========== hero-slide ==========-*/
.slide-bg1 {
  background-image: url("../images/slider-img1.jpg");
}

.slide-bg2 {
  background-image: url("../images/slider-img2.jpg");
}

.slide-bg3 {
  background-image: url("../images/slider-img3.jpg");
}

.slide-bg4 {
  background-image: url("../images-test/stock-photo-medical-students-listening-sitting-at-desk-at-the-university-1135299965.jpg");
}

.slide-bg5 {
  background-image: url("../images-test/home-hero-1920.jpg");
}

.owl-dot-and-nav .owl-dots div {
  border: 2px solid lightgray;
}

/*==================================
    BREADCRUMB
 ==================================*/
.breadcrumb-area {
  background-color: #f5f5f5;
  min-height: 300px;
  padding-top: 2em;
  padding-bottom: 2em;
  color: #fff;
  background-image: none;
  background-size: cover;
  background-position: center;
}

@media (max-width: 375px) {
  .breadcrumb-area {
    min-height: 220px;
  }
}
.breadcrumb-area:before {
  background-color: #3f3f3f;
  opacity: 0.6;
}

.breadcrumb-area.breadcrumb-detail-area:before {
  opacity: 0.8;
}

.breadcrumb-content .section__title {
  text-transform: none;
}

.breadcrumb-content.breadcrumb-content-light li {
  color: #6d6d6d;
}

.breadcrumb-content.breadcrumb-content-light li a {
  color: #6d6d6d;
}

.breadcrumb-content.breadcrumb-detail-content {
  padding-right: 0;
}

.breadcrumb-detail-area {
  text-align: inherit;
  height: auto;
}

@media (max-width: 768px) {
  .breadcrumb-detail-area {
    height: auto;
  }
}
@media (max-width: 425px) {
  .breadcrumb-detail-area {
    height: auto;
  }
}
@media (max-width: 320px) {
  .breadcrumb-detail-area {
    height: auto;
  }
}
/*==================================
    CATEGORY
 ==================================*/
.category-item-layout-2 {
  background-color: #b2cc00;
}

.category-item-layout-2 .category-content {
  color: #3f3f3f;
}

.category-item-layout-2 .icon-element {
  color: #b2cc00;
  background-color: #fff;
  line-height: 62px;
  font-size: 33px;
}

.category-item-layout-2 .icon-element.fa-elearning {
  font-size: 41px;
}

.category-item-layout-2 .cat__title {
  color: #fff;
  font-size: 24px;
}

@media (max-width: 991px) {
  .category-item-layout-2 .cat__title {
    font-size: 20px;
  }
}
.category-item-layout-2:before {
  background-image: url("../images/img31.jpg");
}

.category-item-layout-2:hover .icon-element {
  background-color: #b2cc00;
  color: #fff;
}

.category-item-layout-2:hover .cat__title {
  color: #b2cc00;
}

.category--item-layout-2:hover {
  background-color: #fff;
}

.category--item-layout-2:hover .cat__title {
  color: #b2cc00;
}

.course-detail.breadcrumb-area {
  min-height: auto;
}

/*==================================
    FOOTER
 ==================================*/
.copyright-content .footer__logo {
  margin-right: 40px;
  max-height: 60px;
}

/*-===============================
    CTA
===============================-*/
.get-start-area {
  background-image: url("../images-test/stock-photo-portrait-of-focused-middle-aged-businessman-in-headphones-listening-to-audio-business-course-at-1483169069.jpg");
}

.get-start-area:before {
  background-color: #3f3f3f;
  opacity: 0.65;
}

/*-===============================
    CARDS
===============================-*/
/*-=========== cards ==========-*/
.card-image {
  border: 1px solid rgba(127, 136, 151, 0.2);
  border-bottom: none;
}

.card-image .card-badge-2 {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  text-align: right;
}

.card-image .card-badge-2 .badge-label-3,
.card-image .card-badge-2 .badge-label-4 {
  padding: 2px 10px 2px 12px;
}

.card-image .card-badge-3 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  text-align: right;
}

.card-image .card-badge-3 .badge-label-3,
.card-image .card-badge-3 .badge-label-4 {
  padding: 2px 10px 2px 12px;
}

.card__label-text {
  background-color: #6d6d6d;
  color: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  font-size: 14px;
  line-height: 1.2em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.card__title {
  line-height: 1.2em;
  font-size: 20px;
}

.card-duration {
  line-height: 1.4em;
  font-size: 15px;
}

.course-content-wrapper .card-content {
  min-height: 305px;
}

@media (max-width: 767px) {
  .course-content-wrapper .card-content {
    min-height: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .course-content-wrapper .card-content {
    min-height: 270px;
  }
}
.card-content {
  min-height: 275px;
  padding: 20px 20px 20px 20px;
}

/*-=========== podcasts ==========-*/
.podcast-theme .owl-dot-and-nav .owl-dots div.active,
.podcast-theme .owl-dot-and-nav .owl-dots div:hover {
  background-color: #f36f32;
  border-color: #f36f32;
}

.podcast-theme .card__title {
  margin: 0;
}

.podcast-theme .card__title a:hover {
  color: #f36f32;
}

.podcast-theme .page-navigation .page-navigation-nav li:hover .page-go-link, .podcast-theme .page-navigation .page-navigation-nav li.active .page-go-link {
  background-color: #f36f32;
}

.podcast-theme .page-navigation .page-go-link,
.podcast-theme .page-navigation .page-go {
  color: #f36f32;
}

.podcast-theme .page-navigation .page-go:hover {
  background-color: #f36f32;
}

.podcast-theme .sort-ordering .dropdown-item.active {
  background-color: #f36f32;
}

.podcast-theme .sort-ordering .dropdown-item:hover {
  color: #f36f32;
  background-color: rgba(243, 111, 50, 0.1);
}

.podcast-theme .loader .spinner .path {
  stroke: #f36f32;
}

.podcast-theme .card-preview.tooltipstered .card-content {
  border-bottom: 5px solid #f36f32 !important;
}

.podcast-theme .main-menu a.active, .podcast-theme .main-menu a:hover {
  color: #f36f32;
}

.podcast-theme .main-menu a:after {
  background-color: #f36f32;
}

.podcast-theme .breadcrumb-area:before {
  opacity: 0.4;
}

/*-=========== elearning ==========-*/
.elearning-theme .card-preview.tooltipstered .card-content {
  border-bottom: 5px solid #b2cc00 !important;
}

.course-content-wrapper .cards-podcasts .card-content {
  min-height: 225px;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .course-content-wrapper .cards-podcasts .card-content {
    min-height: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .course-content-wrapper .cards-podcasts .card-content {
    min-height: 220px;
  }
}
.course-content-wrapper .cards-podcasts .card__title {
  margin: 0;
}

.cards-podcasts .card-content {
  min-height: 225px;
}

#hap-wrapper,
#hap-wrapper2,
#hap-wrapper-single {
  max-width: 1170px;
  margin: 30px auto;
  font-family: "Mukta", "Raleway", sans-serif;
}

#hap-wrapper.hap-brona-light .hap-tooltip,
#hap-wrapper2.hap-brona-light .hap-tooltip,
#hap-wrapper-single.hap-brona-light .hap-tooltip {
  background: #f36f32;
}

#hap-wrapper.hap-brona-light .hap-player-title,
#hap-wrapper2.hap-brona-light .hap-player-title,
#hap-wrapper-single.hap-brona-light .hap-player-title {
  font-size: 24px;
}

#hap-wrapper.hap-brona-light .hap-player-artist,
#hap-wrapper2.hap-brona-light .hap-player-artist,
#hap-wrapper-single.hap-brona-light .hap-player-artist {
  font-size: 16px;
}

#hap-wrapper.hap-brona-light .hap-btn:hover svg,
#hap-wrapper.hap-brona-light .hap-btn:hover i,
#hap-wrapper.hap-brona-light .hap-contr-btn:hover > svg,
#hap-wrapper.hap-brona-light .hap-contr-btn-hover svg,
#hap-wrapper.hap-brona-light .hap-contr-btn:hover i,
#hap-wrapper.hap-brona-light .hap-contr-btn-hover i,
#hap-wrapper2.hap-brona-light .hap-btn:hover svg,
#hap-wrapper2.hap-brona-light .hap-btn:hover i,
#hap-wrapper2.hap-brona-light .hap-contr-btn:hover > svg,
#hap-wrapper2.hap-brona-light .hap-contr-btn-hover svg,
#hap-wrapper2.hap-brona-light .hap-contr-btn:hover i,
#hap-wrapper2.hap-brona-light .hap-contr-btn-hover i,
#hap-wrapper-single.hap-brona-light .hap-btn:hover svg,
#hap-wrapper-single.hap-brona-light .hap-btn:hover i,
#hap-wrapper-single.hap-brona-light .hap-contr-btn:hover > svg,
#hap-wrapper-single.hap-brona-light .hap-contr-btn-hover svg,
#hap-wrapper-single.hap-brona-light .hap-contr-btn:hover i,
#hap-wrapper-single.hap-brona-light .hap-contr-btn-hover i {
  color: #f36f32;
}

#hap-wrapper.hap-brona-light .hap-progress-level,
#hap-wrapper.hap-brona-light .hap-volume-level,
#hap-wrapper.hap-brona-light .hap-preloader,
#hap-wrapper2.hap-brona-light .hap-progress-level,
#hap-wrapper2.hap-brona-light .hap-volume-level,
#hap-wrapper2.hap-brona-light .hap-preloader,
#hap-wrapper-single.hap-brona-light .hap-progress-level,
#hap-wrapper-single.hap-brona-light .hap-volume-level,
#hap-wrapper-single.hap-brona-light .hap-preloader {
  background: #f36f32;
}

#hap-wrapper.hap-brona-light .hap-media-time-current,
#hap-wrapper.hap-brona-light .hap-media-time-total,
#hap-wrapper2.hap-brona-light .hap-media-time-current,
#hap-wrapper2.hap-brona-light .hap-media-time-total,
#hap-wrapper-single.hap-brona-light .hap-media-time-current,
#hap-wrapper-single.hap-brona-light .hap-media-time-total {
  font-size: 14px !important;
}

#hap-wrapper.hap-brona-light .hap-btn-play,
#hap-wrapper.hap-brona-light .hap-btn-pause,
#hap-wrapper2.hap-brona-light .hap-btn-play,
#hap-wrapper2.hap-brona-light .hap-btn-pause,
#hap-wrapper-single.hap-brona-light .hap-btn-play,
#hap-wrapper-single.hap-brona-light .hap-btn-pause {
  font-size: 25px;
  /*i {
     	color: $theme-color-9;
     }
     &:hover i {
     	color: #666;
     }*/
}

#hap-wrapper.hap-brona-light .hap-player-thumb,
#hap-wrapper2.hap-brona-light .hap-player-thumb,
#hap-wrapper-single.hap-brona-light .hap-player-thumb {
  border-radius: 0;
}

.hap-playlist-holder .hap-playlist-item-selected .hap-playlist-title-wrap {
  color: #f36f32;
}

.hap-player-outer {
  box-shadow: 1px 1px 20px #dca389;
}

.hap-brona.hap-breakpoint-650 .hap-player-thumb-wrapper {
  height: 140px !important;
  padding-top: 0;
  display: flex;
  justify-content: center;
}

.hap-brona.hap-breakpoint-650 .hap-player-thumb-wrapper .hap-player-thumb {
  max-width: 140px;
  max-height: 140px;
  position: relative;
}

.hap-brona.hap-breakpoint-650 .hap-player-right {
  padding-top: 15px;
}

/*-=========== tooltipster ==========-*/
.tooltipster-sidetip .tooltipster-box {
  background-color: #fff;
  border: 1px solid #6d6d6d;
  -webkit-box-shadow: 0 0 50px rgba(63, 63, 63, 0.9);
  -moz-box-shadow: 0 0 50px rgba(63, 63, 63, 0.9);
  box-shadow: 0 0 50px rgba(63, 63, 63, 0.9);
}

.tooltipster-sidetip .tooltipster-arrow-background {
  border: 30px solid transparent;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow {
  margin-top: 15px;
  right: -22px;
  height: 60px;
  width: 30px;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow {
  margin-top: 15px;
  left: -42px;
  height: 60px;
  width: 50px;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow {
  bottom: -21px;
  height: 31px;
  margin-left: -30px;
  width: 60px;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow {
  height: 60px;
  margin-left: -30px;
  top: -40px;
  width: 60px;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-background {
  border-left-color: #000;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-background {
  border-right-color: #000;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-background {
  border-top-color: #000;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-background {
  border-bottom-color: #000;
}

/*
.tooltipster-sidetip.tooltipster-left .tooltipster-box {
    border-right: 15px solid #B2CC00;
}
.tooltipster-sidetip.tooltipster-right .tooltipster-box {
    border-left: 15px solid #B2CC00;
}
.tooltipster-sidetip.tooltipster-top .tooltipster-box {
    border-bottom: 15px solid #B2CC00;
}
.tooltipster-sidetip.tooltipster-bottom .tooltipster-box {
    border-top: 15px solid #B2CC00;
}
*/
.tooltipster-sidetip.tooltipster-left .tooltipster-box {
  border-right: 15px solid #3f3f3f;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-box {
  border-left: 15px solid #3f3f3f;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-box {
  border-bottom: 15px solid #3f3f3f;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-box {
  border-top: 15px solid #3f3f3f;
}

/*-===============================
    COURSES
===============================-*/
.instructor-img {
  width: auto;
}

.instructor-img img {
  overflow: hidden;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  width: 90px;
  height: 90px;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .instructor-img img {
    width: 90px;
    height: 90px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .instructor-img img {
    width: 90px;
    height: 90px;
  }
}
.bg-circle-score {
  border-radius: 50%;
  height: 140px;
  width: 140px;
  background-color: #b2cc00;
  padding-top: 26px;
}

/*================ course-dashboard-sidebar-column ===================*/
.course-dashboard-sidebar-column {
  top: auto;
}

/*================ course in summary sidebar ===================*/
.course-item-link-blocked {
  position: relative;
}

.course-item-link-blocked:hover {
  background-color: transparent !important;
}

.course-item-link-blocked .custom-checkbox {
  opacity: 0.5;
  margin-right: 15px !important;
}

.course-item-link-blocked .course-item-content {
  opacity: 0.5;
}

.course-list .course-item-link.active {
  background-color: rgba(178, 204, 0, 0.5);
}

.course-progress {
  position: absolute;
  top: 1px;
  right: 50px;
}

@media (max-width: 992px) {
  .course-progress {
    right: 15px;
  }
}
/* ----------------------------------------------------------------
	PLAYER
-----------------------------------------------------------------*/
.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: #b2cc00;
  color: #fff;
}

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(178, 204, 0, 0.5);
  outline: 0;
}

.plyr__control--overlaid {
  background: #b2cc00;
}

.plyr--full-ui input[type=range] {
  color: #b2cc00;
}

.embed-video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-video-container iframe,
.embed-video-container object,
.embed-video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* ----------------------------------------------------------------
	ICON BOX
-----------------------------------------------------------------*/
a.icon-box .info-content {
  line-height: 1.2em;
}

a.icon-box:hover {
  box-shadow: 3px 3px 23px 4px rgba(63, 63, 63, 0.1);
}

a.icon-box.active {
  background-color: #b2cc01;
}

.accordion-shared .card-header {
  background-color: #f5f5f5;
}

/*-===============================
    COUNTER
===============================-*/
.counter-item .count__icon-2 {
  position: absolute;
  top: 52px;
  right: 115px;
  font-size: 35px;
  color: rgba(127, 136, 151, 0.5);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-item .count__icon-2 {
    left: 57%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .counter-item .count__icon-2 {
    left: 60%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .counter-item .count__icon-2 {
    left: 61%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
/*-===============================
    QUIZZ
===============================-*/
.quiz-picto-alert {
  position: absolute;
  top: 0;
  right: 5px;
}

/* ----------------------------------------------------------------
	EXTENDED BOOTSTRAP
-----------------------------------------------------------------*/
/* Line Height */
.line-height-initial {
  line-height: initial !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-1-1 {
  line-height: 1.1 !important;
}

.line-height-2 {
  line-height: 1.2 !important;
}

.line-height-3 {
  line-height: 1.3 !important;
}

.line-height-4 {
  line-height: 1.4 !important;
}

.line-height-5 {
  line-height: 1.5 !important;
}

.line-height-6 {
  line-height: 1.6 !important;
}

.line-height-7 {
  line-height: 1.7 !important;
}

.line-height-8 {
  line-height: 1.8 !important;
}

.line-height-9 {
  line-height: 1.9 !important;
}

/* ----------------------------------------------------------------
	TINY DEFAULT
-----------------------------------------------------------------*/
.tiny-default {
  line-height: 1.7em;
}

.tiny-default a {
  color: #b2cc00 !important;
  text-decoration: underline !important;
}

.tiny-default a:hover {
  color: #3f3f3f !important;
  text-decoration: underline !important;
}

.tiny-default ul,
.tiny-default ol {
  margin-left: 3em;
}

.tiny-default ul {
  list-style-type: disc;
}

.tiny-default ol {
  list-style-type: decimal;
}

.tiny-default p {
  padding: 0;
  margin: 1.5em 0;
}

.tiny-default img {
  max-width: 100%;
  height: auto;
}

.tiny-default table {
  border: thin solid #f5f5f5;
}

.tiny-default table td {
  padding: 0.5em;
}

.tiny-default td,
.tiny-default th {
  border: thin solid #f5f5f5;
}

.tiny-default h1, .tiny-default h2, .tiny-default h3, .tiny-default h4, .tiny-default h5, .tiny-default h6 {
  font-weight: 500;
}

.tiny-default h1 {
  font-size: 2.5rem;
}

.tiny-default h2 {
  font-size: 2rem;
}

.tiny-default h3 {
  font-size: 1.75rem;
}

.tiny-default h4 {
  font-size: 1.5rem;
}

.tiny-default h5 {
  font-size: 1.25rem;
}

.tiny-default h6 {
  font-size: 1rem;
}

/* ----------------------------------------------------------------
	COURSES DETAIL CONTENT
-----------------------------------------------------------------*/
.lecture-viewer-tiny-default ul, .lecture-viewer-tiny-default ol {
  margin-left: 3em;
  list-style-type: disc;
}

/* ----------------------------------------------------------------
	PARTNERS
-----------------------------------------------------------------*/
.partner-intro-banner .partner-logo img,
.partner-intro-content .partner-logo img,
.partner-intro-summary .partner-logo img {
  max-height: 160px;
  max-width: 160px;
  background-color: #fff;
  padding: 15px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.partner-intro-banner .partner-info-box,
.partner-intro-content .partner-info-box,
.partner-intro-summary .partner-info-box {
  background-color: #b2cc00;
  border-color: #b2cc00;
  -webkit-box-shadow: 0 0 40px rgba(14, 16, 48, 0.05);
  -moz-box-shadow: 0 0 40px rgba(14, 16, 48, 0.05);
  box-shadow: 0 0 40px rgba(14, 16, 48, 0.05);
  padding: 25px 40px 15px 40px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  position: relative;
  overflow: hidden;
  margin-bottom: 0px;
  z-index: 1;
}

.partner-intro-banner .partner-info-box .hover-overlay:before, .partner-intro-banner .partner-info-box .hover-overlay:after,
.partner-intro-content .partner-info-box .hover-overlay:before,
.partner-intro-content .partner-info-box .hover-overlay:after,
.partner-intro-summary .partner-info-box .hover-overlay:before,
.partner-intro-summary .partner-info-box .hover-overlay:after {
  position: absolute;
  content: "";
  top: 50%;
  left: -90px;
  width: 100%;
  height: 540px;
  z-index: -1;
  transition: all 0.5s ease;
  -webkit-transform: translateY(0) rotate(10deg);
  -moz-transform: translateY(0) rotate(10deg);
  -ms-transform: translateY(0) rotate(10deg);
  -o-transform: translateY(0) rotate(10deg);
  transform: translateY(0) rotate(10deg);
  background-color: rgba(255, 255, 255, 0.2);
}

.partner-intro-banner .partner-info-box .hover-overlay:after,
.partner-intro-content .partner-info-box .hover-overlay:after,
.partner-intro-summary .partner-info-box .hover-overlay:after {
  top: 70%;
  background-color: rgba(255, 255, 255, 0.3);
}

.partner-intro-banner .partner-info-box .partner__title,
.partner-intro-content .partner-info-box .partner__title,
.partner-intro-summary .partner-info-box .partner__title {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 22px;
  margin-top: 25px;
  margin-bottom: 20px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  color: #fff;
}

.partner-intro-banner .partner-info-box .partner__text,
.partner-intro-content .partner-info-box .partner__text,
.partner-intro-summary .partner-info-box .partner__text {
  line-height: 28px;
  margin-bottom: 20px;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.partner-intro-banner .partner-info-box:before,
.partner-intro-content .partner-info-box:before,
.partner-intro-summary .partner-info-box:before {
  position: absolute;
  content: "";
  top: 30%;
  left: -90px;
  width: 100%;
  height: 540px;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: -1;
  transition: all 0.5s ease;
  -webkit-transform: translateY(0) rotate(10deg);
  -moz-transform: translateY(0) rotate(10deg);
  -ms-transform: translateY(0) rotate(10deg);
  -o-transform: translateY(0) rotate(10deg);
  transform: translateY(0) rotate(10deg);
}

.partner-intro-banner .feature-content-wrap {
  margin-top: -60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .partner-intro-banner .feature-content-wrap {
    margin-top: -30px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .partner-intro-banner .feature-content-wrap {
    margin-top: -25px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .partner-intro-banner .feature-content-wrap {
    margin-top: -15px;
  }
}
.partner-intro-content .feature-content-wrap {
  margin-top: 0;
}

.partner-intro-content .container {
  padding-left: 0;
  padding-right: 0;
}

.partner-intro-content .partner-logo img {
  max-height: 120px;
  max-width: 120px;
  padding: 15px;
}

.partner-intro-summary .partner-logo img {
  max-height: 120px;
  max-width: 120px;
  padding: 15px;
}

.partner-intro-summary .partner-info-box {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}

.partner-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.partner-flex .partner-logo,
.partner-flex .partner-link {
  margin-left: 10px;
  margin-right: 10px;
}
